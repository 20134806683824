import axios from "axios";

export const Post = {
    created() {
        this.fetch_news();
    },
    methods: {
        fetch_news: function (locale = null) {
            if(locale === null) {
                locale = this.locale || localStorage.locale || 'vi';
            }
            let url = new URL(window.location.href);
            if (this.$route.params.slug) {
                this.fetch_post(this.$route.params.slug);
            } else {
                if (this.page == 'news') {
                    if (url.searchParams.get('page')) {
                        this.current_page = url.searchParams.get('page');
                    }
                    this.fetch_posts(locale);
                } else if (this.page == 'corporate') {
                    this.fetch_posts(locale);
                } else if (this.page == 'hot_news') {
                    this.fetch_hot_posts(locale);
                }
            }
        },
        fetch_post: function (slug) {
            var self = this;
            axios.get(this.posts_url, {params: {slug: slug}})
                .then(res => {
                    var data = res.data;
                    if (data.length) {
                        self.post = data[0];
                        self.fetch_related_posts([self.post.id], self.post.lang);
                    }
                })
                .catch(err => {
                    console.log('Getting News data', err);
                });
        },
        fetch_posts: function (locale) {
            var self = this;
            axios.get(this.posts_url, {
                params: {
                    per_page: this.numberposts,
                    lang: locale,
                    page: this.current_page
                }
            })
                .then(res => {
                    self.posts = res.data;
                    self.total_pages = parseInt(res.headers['x-wp-totalpages']);
                    self.total_posts = parseInt(res.headers['x-wp-total']);
                })
                .catch(err => {
                    console.log('Getting News data', err);
                });
        },
        fetch_hot_posts: function (locale) {
            var self = this;
            axios.get(this.posts_url, {params: {per_page: 5, lang: locale, sticky: true}})
                .then(res => {
                    self.posts = res.data;
                    self.total_pages = parseInt(res.headers['x-wp-totalpages']);
                    self.total_posts = parseInt(res.headers['x-wp-total']);
                })
                .catch(err => {
                    console.log('Getting News data', err);
                });
        },
        fetch_related_posts: function (exclude_ids, locale) {
            var self = this;
            axios.get(this.posts_url, {params: {per_page: this.numberposts, lang: locale, exclude: exclude_ids}})
                .then(res => {
                    self.related_posts = res.data;
                })
                .catch(err => {
                    console.log('Getting News data', err);
                });
        },
        next_page: function () {
            return this.total_pages >= this.current_page + 1 ? this.current_page + 1 : 0;
        },
        prev_page: function () {
            return this.current_page - 1 >= 1 ? this.current_page - 1 : 0;
        }
    },
    data() {
        return {
            numberposts: 10,
            current_page: 1,
            posts_url: process.env.VUE_APP_POSTS_API || '/cms/wordpress/index.php/wp-json/wp/v2/posts',
            total_pages: 0,
            total_posts: 0,
            posts: [],
            related_posts: [],
            post: {}
        }
    },
    computed: {
        lastNews: function () {
            return this.posts[0];
        }
    }
}